export default (data = {}) => {
    return {
        "PARTE FRONTAL": [
            {code: '100',label:'Bomper',status:'Bueno',qty: 1,comment:null},	        		
            {code: '101',label:'Placa',status:'Bueno',qty: 1},						
            {code: '102',label:'Persiana',status:'Bueno',qty: 1},							
            {code: '103',label:'Emblema persiana',status:'Bueno',qty: 1},						
            {code: '104',label:'Capot',status:'Bueno',qty: 1},						
            {code: '105',label:'Grifos',status:'Bueno',qty: 1},				
            {code: '106',label:'Lampara Derecha',status:'Bueno',qty: 1},						
            {code: '107',label:'Direccional Derecha',status:'Bueno',qty: 1},				
            {code: '108',label:'Lampara Izquierda',status:'Bueno',qty: 1},					
            {code: '109',label:'Direccional Izquierda',status:'Bueno',qty: 1},					
            {code: '110',label:'Brazos limpiabrisas',status:'Bueno',qty: 1},						
            {code: '111',label:'Plumillas',status:'Bueno',qty: 1},					
            {code: '112',label:'Capota',status:'Bueno',qty: 1},		
        ],
        "PARTE TRASERA": [
            {code: '300',label:'Stop Derecho',status:'Bueno',qty: 1},
            {code: '301',label:'Direccional Derecha',status:'Bueno',qty: 1},
            {code: '302',label:'Tapa Baul',status:'Bueno',qty: 1},
            {code: '303',label:'Bomper',status:'Bueno',qty: 1},
            {code: '304',label:'Placa',status:'Bueno',qty: 1},
            {code: '305',label:'Emblemas',status:'Bueno',qty: 1},
            {code: '306',label:'Espoiler',status:'Bueno',qty: 1},
            {code: '307',label:'Tercer Stop',status:'Bueno',qty: 1},
        ],
        "PARTE EXTERIOR IZQUIERDA": [
            {code: '400',label:'Guardafango Delantero',status:'Bueno',qty: 1},	
            {code: '401',label:'Llanta Delantera',status:'Bueno',qty: 1},		
            {code: '402',label:'Rin Delantero',status:'Bueno',qty: 1},		
            {code: '403',label:'Copa Delantera',status:'Bueno',qty: 1},		
            {code: '404',label:'Bocel Guardafango',status:'Bueno',qty: 1},		
            {code: '405',label:'Direccional Guardafango',status:'Bueno',qty: 1},	
            {code: '406',label:'Bote Guardafango',status:'Bueno',qty: 1},		
            {code: '407',label:'Puerta Delantera',status:'Bueno',qty: 1},		
            {code: '408',label:'Boceles Puertas',field:'bomper'},	
            {code: '409',label:'Cajeta',status:'Bueno',qty: 1},		
            {code: '410',label:'Puerta Trasera',field:'bomper'},	
            {code: '411',label:'Manijas',field:'bomper'},	
            {code: '412',label:'Llanta Trasera',status:'Bueno',qty: 1},		
            {code: '413',label:'Rin Trasero',status:'Bueno',qty: 1},		
            {code: '414',label:'Copa Trasera',status:'Bueno',qty: 1},		
            {code: '415',label:'Costado Trasero',status:'Bueno',qty: 1},	
        ],
        "PARTE EXTERIOR DERECHA": 
        [
            {code: '200',label:'Guardafango Delantero',status:'Bueno',qty: 1},	
            {code: '201',label:'Llanta Delantera',status:'Bueno',qty: 1},		
            {code: '202',label:'Rin Delantero',status:'Bueno',qty: 1},		
            {code: '203',label:'Copa Delantera',status:'Bueno',qty: 1},		
            {code: '204',label:'Bocel Guardafango',status:'Bueno',qty: 1},		
            {code: '205',label:'Direccional Guardafango',status:'Bueno',qty: 1},	
            {code: '206',label:'Bote Guardafango',status:'Bueno',qty: 1},		
            {code: '207',label:'Puerta Delantera',status:'Bueno',qty: 1},		
            {code: '208',label:'Boceles Puertas',field:'bomper'},	
            {code: '209',label:'Cajeta',status:'Bueno',qty: 1},		
            {code: '210',label:'Puerta Trasera',field:'bomper'},	
            {code: '211',label:'Manijas',field:'bomper'},	
            {code: '212',label:'Llanta Trasera',status:'Bueno',qty: 1},		
            {code: '213',label:'Rin Trasero',status:'Bueno',qty: 1},		
            {code: '214',label:'Copa Trasera',status:'Bueno',qty: 1},		
            {code: '215',label:'Costado Trasero',status:'Bueno',qty: 1},	
        ],

  
        "PARTE CABINA": [
            {code: '500',label:'Millaré',status:'Bueno',qty: 1},
            {code: '501',label:'Tablero de Instrumentos',status:'Bueno',qty: 1},
            {code: '502',label:'Timón',status:'Bueno',qty: 1},
            {code: '503',label:'Consola',status:'Bueno',qty: 1},
            {code: '504',label:'Guantera',status:'Bueno',qty: 1},
            {code: '505',label:'Encendedor',status:'Bueno',qty: 1},
            {code: '506',label:'Suitches calefección',status:'Bueno',qty: 1},
            {code: '507',label:'Parasoles',status:'Bueno',qty: 1},
            {code: '508',label:'Luz Techo',status:'Bueno',qty: 1},
            {code: '509',label:'Cojinería',status:'Bueno',qty: 1},
            {code: '510',label:'Tapizado Puertas',status:'Bueno',qty: 1},
            {code: '511',label:'Seguros Puertas',status:'Bueno',qty: 1},
            {code: '512',label:'Controles elevavidrios',status:'Bueno',qty: 1},
            {code: '513',label:'Manijas Internas',status:'Bueno',qty: 1},
            {code: '514',label:'Descansa brazos',status:'Bueno',qty: 1},
            {code: '515',label:'Asientos',status:'Bueno',qty: 1},
            {code: '516',label:'Cinturones de Seguridad',status:'Bueno',qty: 1},
            {code: '517',label:'Tapetes',status:'Bueno',qty: 1},
        ],
        "VIDRIOS": [
            {code: '600',label:'Panorámico Delantero',status:'Bueno',qty: 1},
            {code: '601',label:'Puerta Del. Derecha',status:'Bueno',qty: 1},
            {code: '602',label:'Retrovisor Derecho',status:'Bueno',qty: 1},
            {code: '603',label:'Puerta Trasera Derecha',status:'Bueno',qty: 1},
            {code: '604',label:'Panorámico Trasero',status:'Bueno',qty: 1},
            {code: '605',label:'Puerta Trasera Izquierda',status:'Bueno',qty: 1},
            {code: '606',label:'Puerta Del. Izquierda',status:'Bueno',qty: 1},
            {code: '607',label:'Retrovisor Izquierdo',status:'Bueno',qty: 1},
            {code: '608',label:'Aletas',status:'Bueno',qty: 1},
            {code: '609',label:'Retrovisor interno',status:'Bueno',qty: 1},
        ],
        "MOTOR": [
            {code: '700',label:'Computadora',status:'Bueno',qty: 1},
            {code: '701',label:'Motor de arranque',status:'Bueno',qty: 1},
            {code: '702',label:'Carburador',status:'Bueno',qty: 1},
            {code: '703',label:'Radiador',status:'Bueno',qty: 1},
            {code: '704',label:'Compresor',status:'Bueno',qty: 1},
            {code: '705',label:'Pito',status:'Bueno',qty: 1},
            {code: '706',label:'Alternador',status:'Bueno',qty: 1},
            {code: '707',label:'Distribuidor',status:'Bueno',qty: 1},
            {code: '708',label:'Cuerpo Mariposa',status:'Bueno',qty: 1},
            {code: '709',label:'Inyectores',status:'Bueno',qty: 1},
            {code: '710',label:'Depurador',status:'Bueno',qty: 1},
            {code: '711',label:'Condensador',status:'Bueno',qty: 1},
            {code: '712',label:'Batería',status:'Bueno',qty: 1},
            {code: '714',label:'Soporte Batería',status:'Bueno',qty: 1},
        ],
        "ACCESORIOS": [
            {code: '800',label:'Tapa de Gasolina',status:'Bueno',qty: 1},
            {code: '801',label:'Llanta de repuesto',status:'Bueno',qty: 1},
            {code: '802',label:'Herramientas',status:'Bueno',qty: 1},
            {code: '803',label:'Extintor',status:'Bueno',qty: 1},
            {code: '804',label:'Gato',status:'Bueno',qty: 1},
            {code: '805',label:'Control de Alarma',status:'Bueno',qty: 1},
            {code: '806',label:'Radio',status:'Bueno',qty: 1},
            {code: '807',label:'Salpicaderas',status:'Bueno',qty: 1},
            {code: '808',label:'Llaves del vehículo',status:'Bueno',qty: 1},
        ],
        ...data
        }
    }
