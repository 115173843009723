<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        {{ itm.label }}
        <v-spacer></v-spacer>
        <v-icon @click="close()"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-select
              v-model="itm.status"
              hide-details="auto"
              label="Estado"
              :items="itmOptions"
              :rules="f_required"
              outlined
              required
              dense
              @change="updateQty(itm)"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="itm.qty"
              label="QTY"
              hide-details="auto"
              outlined
              type="number"
              dense
              class="text-center"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="itm.note"
              label="Observacion"
              hide-details="auto"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InvItmEdit",
  components: {},
  emits: [],
  data() {
    return {
      itmOptions: ["Bueno", "Rayado", "Golpeado", "Roto", "No tiene", "En Observ"],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
    };
  },
  props: {
    itm: {
      type: Object,
      required: true,
    },

    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    updateQty(e) {
      console.log(e);
      if (e.status === "No tiene") {
        e.qty = 0;
      } else {
        e.qty = 1;
      }
    },
    close() {
      this.$emit("close", false);
    },
  },
  watch: {
    item: function (e) {
      console.log(e);
    },
  },
};
</script>

<style>
.v-btn--example {
  top: 10px;
  position: absolute;
  margin: 0 0 0 0;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.map {
  height: 400px;
}

.btn-upload {
  top: 150px;
}
</style>
