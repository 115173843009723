<template>
  <v-container>
    <v-form v-model="valid" ref="itemForm" @submit="item_submit">
      <v-layout align-center justify-center>
        <v-flex xs12 sm10 md10>
          <v-row justify="center">
            <v-col cols="auto" v-for="(child, index) in items" :key="index">
              <v-card width="400" elevation="10">
                <v-card-title>
                  {{ index }}
                </v-card-title>
                <v-card-text>
                  <v-list width="100%">
                    <v-list-item
                      v-for="(itm, idx) in child"
                      :key="idx"
                      dense
                      @click="editItm(itm)"
                    >
                      <v-list-item-content>
                        {{ itm.label }}<br />
                        <small v-if="itm.note">{{ itm.note }}</small>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ itm.status }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
      <!-- <v-expansion-panels tile width="100%" fill-height>
        <v-expansion-panel v-for="(child, i) in items" :key="i">
          <v-expansion-panel-header>
            <h3>{{ i }}</h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-col v-for="(itm, idx) in child" :key="idx">
              <v-row>
                <v-col cols="9">
                  <v-select
                    v-model="itm.status"
                    hide-details="auto"
                    :label="itm.label"
                    :items="itmOptions"
                    :rules="f_required"
                    outlined
                    required
                    dense
                    @change="updateQty(itm)"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="itm.qty"
                    label="QTY"
                    hide-details="auto"
                    outlined
                    type="number"
                    dense
                    class="text-center"
                  />
                </v-col>
                <v-col cols="12" v-if="itm.status === 'En Observ'">
                  <v-text-field
                    v-model="itm.note"
                    label="Observacion"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->

      <!-- <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="item.typeid"
                hide-details="auto"
                label="Tipo Documento"
                :items="setting['type_document_identifications']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="item.PartyIdentification"
                :rules="f_required"
                label="ID"
                hide-details="auto"
                required
                outlined
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.PartyName"
                label="Nombre"
                hide-details="auto"
                required
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item.AddressLine"
                label="Dirección"
                hide-details="auto"
                required
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.city_code"
                hide-details="auto"
                label="Ciudad"
                :items="setting['municipalities']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.Department"
                hide-details="auto"
                label="Departamento"
                :items="setting['departments']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="item.client_email"
                id="name"
                :rules="f_required"
                label="Correo electrónico"
                hide-details="auto"
                required
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item.client_phone"
                label="Teléfono"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.type_organization_id"
                hide-details="auto"
                label="Tipo persona"
                :items="setting['type_organizations']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.type_regime_id"
                hide-details="auto"
                label="Tipo de régimen"
                :items="setting['type_regimes']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.type_liability_id"
                hide-details="auto"
                label="Tipo de responsabilidad"
                :items="setting['type_liabilities']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                readonly
                v-model="item.store_code"
                label="Cod. POSWEB"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.sync_code"
                hide-details="auto"
                label="SYNC"
                :items="lstSync"
                item-value="id"
                item-text="name"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.environment"
                hide-details="auto"
                label="Ambiente"
                :items="setting['type_environments']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
          </v-row> -->
    </v-form>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-title>
          {{ loadingStatus.title }}
        </v-card-title>
        <v-card-text class="pa-6">
          <p>{{ loadingStatus.text }}</p>
          <v-progress-linear indeterminate color="green" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <editCarInv
      :itm="editItem"
      :dialog="editItemDialog"
      @close="editItemDialog = false"
    />
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createCar from "../../utils/carItem.js";
import editCarInv from "../../components/editCarItem.vue";
export default {
  name: "Company",
  components: { editCarInv },
  beforeMount() {},
  mounted() {
    // this.get_settings();
    console.log(this.item);
  },
  data() {
    return {
      valid: false,
      editItemDialog: false,
      editItem: { code: null, label: null, status: null, qty: null },
      loading_status: false,
      dialog_confirm: false,
      items: createCar(),

      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      reg_delete: null,
      loadingStatus: {
        title: "Proceso",
        text: "Conectando con CarDealer Server",
      },
    };
  },
  methods: {
    editItm(e) {
      this.editItem = e;
      this.editItemDialog = true;
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var qry = {
          store: window.store.store_id,
          table: "f_companies",
          data: [this.item],
        };
        this.loadingStatus = {
          title: "Proceso",
          text: "Actualizando registro.",
        };
        this.loading_status = true;
        webserver("put_dataTable", qry, (data) => {
          this.loading_status = false;
          //this.resetDoc();
          //this.loading_vendors = false;
          console.log(data);
          //this.print_doc(data);
        });
      }
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: "f_company",
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      console.log(qry);
      // webserver("delete_record", qry, () => {
      //   this.reg_delete = null;
      //   //this.dialog_confirm = false;
      //   this.loading_status = false;
      //   this.$emit("refresh", true);
      // });
    },
    GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    },
    get_items() {
      var qry = {
        store: window.store.store_id,
        table: "f_companies",
        filters: [
          {
            field: "store_code",
            operator: "=",
            value: window.store.store_id,
          },
        ],
      };
      this.loadingStatus = {
        title: "Proceso",
        text: "Conectando con la DIAN",
      };
      this.loading_status = true;
      webserver("get_dataTable", qry, (data) => {
        console.log("get_dataTable>", data);
        this.loading_status = false;
        this.item.store_code = window.store.store_id;
        if (data.length > 0) {
          this.item = data[0];
          this.cityObj = { name: data[0].CityName, code: data[0].city_code };
        }
      });
    },
  },
};
</script>
